import React, { useEffect, useRef, useState } from 'react'
import svgData from '../../Data/svg'
import { useDispatch, useSelector } from 'react-redux';
import { getBodyPattern } from '../../Redux/Action/getBodyPattern';
import { getSleevePattern } from '../../Redux/Action/getSleevePattern';
import { getCapPattern } from '../../Redux/Action/getCapPattern';
import { getColor } from '../../Redux/Action/getColor'
import { Button, message } from "antd";
import { useParams } from "react-router-dom";
import { clearState } from "../../Redux/Reducer/postCustomShirtReducer";
import { postCustomShirt } from "../../Redux/Action/postCustomShirt";
import { Modal } from "react-bootstrap";
import Loaders from "../Loader/Loaders";
import BuyNow from "../../Pages/BuyNow";

const ShirtSvgDisplay = ({
  capColors,
  capMarkingColor,
  sleeveColors,
  sleevesMarkingColor,
  bodyColors,
  bodyMarkingColors,
  bodyId,
  capId,
  sleevesID,
}) => {
  const dispatch = useDispatch();
  const { success, error, data, loading } = useSelector(
    (state) => state.postCustomShirt
  );
  const [modalShow, setModalShow] = useState(false);

  const bodyData = useSelector((state) => state.getBodyPattern.data);
  const sleeveData = useSelector((state) => state.getSleevePattern.data);
  const capData = useSelector((state) => state.getCapPattern.data);
  const svgRef = useRef(null);
  const { id } = useParams();
  const [waterMarkImage, setWaterMarkImage] = useState();
  const [show, setShow] = useState(true);

  const waterMark = {
    fillRule: "evenodd",
    opacity: 0.15,
  };

  useEffect(() => {
    const element = document.getElementById("eeellle");
    setWaterMarkImage(element);
  }, [waterMarkImage]);

  useEffect(() => {
    const ShirtType = id;
    dispatch(getBodyPattern(ShirtType));
    dispatch(getSleevePattern(ShirtType));
    dispatch(getCapPattern(ShirtType));
    dispatch(getColor());
    localStorage.removeItem("imageData");
  }, [dispatch]);

  const downloadImage = async () => {
    const svg = svgRef.current;
    const watermarkElement = svg.querySelector("#eeellle");
    watermarkElement.classList.add("wpMark");

    if (watermarkElement) {
      watermarkElement.remove();
    }

    const svgData = new XMLSerializer().serializeToString(svg);

    if (watermarkElement) {
      svg.appendChild(watermarkElement);
    }

    const formats = ["svg", "png", "jpg"];

    for (const format of formats) {
      const scaleFactor = 3;
      const canvas = document.createElement("canvas");
      const svgSize = svg.getBoundingClientRect();
      canvas.width = svgSize.width * scaleFactor;
      canvas.height = svgSize.height * scaleFactor;
      const ctx = canvas.getContext("2d");
      const img = new Image();

      await new Promise((resolve, reject) => {
        img.onload = () => {
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          let dataURL;

          switch (format) {
            case "svg":
              dataURL = "data:image/svg+xml;base64," + btoa(svgData);
              localStorage.setItem("svgImage", svgData);
              break;
            case "png":
              dataURL = canvas.toDataURL("image/png");
              localStorage.setItem("pngImage", dataURL);
              break;
            case "jpg":
              dataURL = canvas.toDataURL("image/jpeg");
              localStorage.setItem("jpgImage", dataURL);
              break;
            default:
              break;
          }

          resolve();
        };

        img.onerror = (error) => {
          console.error("Image load error: ", error);
          reject(error);
        };

        img.src = "data:image/svg+xml;base64," + btoa(svgData);
      });
    }
  };

  const downloadImageOnlyPng = () => {
    const svg = svgRef.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const svgSize = svg.getBoundingClientRect();
    canvas.width = svgSize.width;
    canvas.height = svgSize.height;
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      localStorage.setItem("image", dataURL);
    };

    img.onerror = (error) => {
      console.error("Image load error: ", error);
    };

    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  };

  const handleClick = async () => {
    const svg = svgRef.current;
    await downloadImageOnlyPng();
    await downloadImage();
    const waterMark = localStorage.getItem("image");
    const withoutWaterMarked = localStorage.getItem("pngImage");

    const formData = new FormData();
    formData.append("markedImage", dataURLtoBlob(waterMark));
    formData.append("image", dataURLtoBlob(withoutWaterMarked));
    formData.append("svgPath", localStorage.getItem("svgImage"));

    try {
      await dispatch(postCustomShirt(formData));
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  useEffect(() => {
    const observerCallback = (mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          mutation.removedNodes.forEach((node) => {
            // Check if the removed node has the 'wpMark' class
            if (
              node.nodeType === Node.ELEMENT_NODE &&
              !node.classList.contains("wpMark")
            ) {
              if (node.id === "eeellle") {
                setShow(false);
              }
            }
          });
        }
      }
    };

    const observer = new MutationObserver(observerCallback);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (success) {
      message.success(success);
      dispatch(clearState());
      localStorage.removeItem("image");
      localStorage.removeItem("pngImage");
      localStorage.removeItem("svgImage");
      localStorage.removeItem("jpgImage");
      setModalShow(true);
    }
    if (error) {
      message.error(error);
      dispatch(clearState());
      localStorage.removeItem("image");
      localStorage.removeItem("pngImage");
      localStorage.removeItem("svgImage");
      localStorage.removeItem("jpgImage");
    }
  }, [success, error]);

  const handleClosee = () => {
    setModalShow(false);
  };

  return (
    <div className="imageeeSvg">
      {show && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 650 1010"
          width="100%"
          height="auto"
          ref={svgRef}
          className="shirtsvg"
          style={{ background: "#FFF" }}
        >
          {svgData?.svg.elements?.map((item) => (
            <g data-name="Layer 1">
              {/* Left Sleeve Outline and Design */}
              {item.id === "left-sleeve" && (
                <g id="left-sleeve">
                  <path style={sleeveColors} d={item.d} />
                </g>
              )}
              {/* Right Sleeve Outline and Design */}
              {item.id === "right-sleeve" && (
                <g id="right-sleeve">
                  <path style={sleeveColors} d={item.d} />
                </g>
              )}
              {item.id === "abc" && (
                <g id="abc">
                  <path d={item.d} />
                </g>
              )}

              {/* Body Outline and Design */}
              {item.id === "body" && (
                <g id="body">
                  <path style={bodyColors} d={item.d} />
                </g>
              )}
              {item.id === "body-highlight" && (
                <g id="body-highlight">
                  <path style={bodyColors} d={item.d} />
                </g>
              )}

              {/* Cap Outline and Design */}
            </g>
          ))}

          {bodyData?.items?.map(
            (item, index) =>
              bodyId === item._id && (
                <svg
                  key={index}
                  className="bboddy"
                  style={{ strokeWidth: "3" }}
                  dangerouslySetInnerHTML={{
                    __html: `${item.SvgPath.replace(
                      /^<div>|<\/div>$/g,
                      ""
                    )}<style>.bboddy path { fill: ${
                      bodyMarkingColors.fill
                    }; } .bboddy polygon { fill: ${
                      bodyMarkingColors.fill
                    }; } .bboddy rect { fill: ${
                      bodyMarkingColors.fill
                    }; } .bboddy ellipse { fill: ${
                      bodyMarkingColors.fill
                    }; } .bboddy circle { fill: ${
                      bodyMarkingColors.fill
                    }; } .bboddy polyline { fill: ${
                      bodyMarkingColors.fill
                    }; } .bboddy line { fill: ${
                      bodyMarkingColors.fill
                    }; }</style>
                    `,
                  }}
                />
              )
          )}
          {/* Mapping Patterns so that match the pattern on svg */}

          {sleeveData?.items?.map(
            (item, index) =>
              sleevesID === item._id && (
                <svg
                  key={index}
                  className="ssleevvee"
                  style={{ strokeWidth: "3" }}
                  dangerouslySetInnerHTML={{
                    __html: `${item.SvgPath.replace(
                      /^<div>|<\/div>$/g,
                      ""
                    )}<style>.ssleevvee path { fill: ${
                      sleevesMarkingColor.fill
                    }; } .ssleevvee circle { fill: ${
                      sleevesMarkingColor.fill
                    }; } .ssleevvee polygon{fill: ${
                      sleevesMarkingColor.fill
                    }; } .ssleevvee rect {fill: ${
                      sleevesMarkingColor.fill
                    }; } .ssleevvee ellipse {fill: ${
                      sleevesMarkingColor.fill
                    }; } .ssleevvee polyline {fill: ${
                      sleevesMarkingColor.fill
                    }; } .ssleevvee line {fill: ${
                      sleevesMarkingColor.fill
                    }; }</style>
                    `,
                  }}
                />
              )
          )}

          {svgData?.svg.elements?.map((item, i) => (
            <g data-name="Layer 1" key={i}>
              {item.id === "outlinee" && (
                <g id="outlinee">
                  {item.pattern.map((itemss, i) => (
                    <path
                      d={itemss.d}
                      style={{ fill: "#e6e7e8", opacity: "0.4" }}
                    />
                  ))}
                </g>
              )}
              {
                <g id="cap">
                  {item.id === "cap-front" && (
                    <path style={capColors} d={item.d} />
                  )}

                  {item.id === "cap-bill" && (
                    <path style={capColors} d={item.d} />
                  )}
                  {item.id === "facecut" && (
                    <path className="mainPattern" d={item.d} />
                  )}
                </g>
              }
            </g>
          ))}

          {capData?.items?.map(
            (item, index) =>
              capId === item._id && (
                <svg
                  key={index}
                  className="ccaapp"
                  style={{ strokeWidth: "3" }}
                  dangerouslySetInnerHTML={{
                    __html: `${item.SvgPath.replace(
                      /^<div>|<\/div>$/g,
                      ""
                    )}<style>.ccaapp path { fill: ${
                      capMarkingColor.fill
                    }; } .ccaapp circle { fill: ${
                      capMarkingColor.fill
                    }; } .ccaapp rect { fill: ${
                      capMarkingColor.fill
                    }; } .ccaapp polygon { fill: ${
                      capMarkingColor.fill
                    }; } .ccaapp ellipse { fill: ${
                      capMarkingColor.fill
                    }; } .ccaapp line { fill: ${
                      capMarkingColor.fill
                    }; } .ccaapp polyline { fill: ${
                      capMarkingColor.fill
                    }; }</style>`,
                  }}
                />
              )
          )}
          <g id="eeellle">
            {svgData?.svg.elements?.map((item) => (
              <g>
                {item.id == "watermark" && (
                  <g id="watermark">
                    <path style={waterMark} d={item.d} id="eeellle" />
                  </g>
                )}

                {item.id == "watermark-1" && (
                  <g id="watermark-1">
                    <path style={waterMark} d={item.d} id="eeellle" />
                  </g>
                )}

                {item.id == "watermark-2" && (
                  <g id="watermark-2">
                    <path style={waterMark} d={item.d} id="eeellle" />
                  </g>
                )}

                {item.id == "watermark-3" && (
                  <g id="watermark-3">
                    <path style={waterMark} d={item.d} id="eeellle" />
                  </g>
                )}

                {item.id == "watermark-4" && (
                  <g id="watermark-4">
                    <path style={waterMark} d={item.d} id="eeellle" />
                  </g>
                )}

                {item.id == "watermark-5" && (
                  <g id="watermark-5">
                    <path style={waterMark} d={item.d} id="eeellle" />
                  </g>
                )}

                {item.id == "watermark-6" && (
                  <g id="watermark-6">
                    <path style={waterMark} d={item.d} id="eeellle" />
                  </g>
                )}

                {item.id == "watermark-7" && (
                  <g id="watermark-7">
                    <path style={waterMark} d={item.d} id="eeellle" />
                  </g>
                )}

                {item.id == "watermark-8" && (
                  <g id="watermark-8">
                    <path style={waterMark} d={item.d} id="eeellle" />
                  </g>
                )}
              </g>
            ))}
          </g>
        </svg>
      )}

      {/* <input type="file" accept="image/*" onChange={handleFileChange} /> */}
      {/* <Select
        value={downloadFormat}
        onChange={(value) => setDownloadFormat(value)}
      >
        <Select.Option value="svg">Download as SVG</Select.Option>
        <Select.Option value="png">Download as PNG</Select.Option>
        <Select.Option value="jpg">Download as JPEG/JPG</Select.Option>
      </Select>

      <Button className="downLOad" onClick={downloadImage}>
        Download Image
      </Button> */}
      {/* <Button className="downLOad" onClick={() => handlePayment("paypal")}>
        PAY PAL
      </Button>
      <Button className="downLOad" onClick={() => handlePayment("stripe")}>
        Stripe
      </Button> */}

      <div className="Pricee">
        <p className="big">$2.00</p>
      </div>

      <div className="button">
        <Button className="buynow" onClick={handleClick} disabled={loading}>
          {loading ? <Loaders /> : "Check Out"}
        </Button>
      </div>

      <Modal show={modalShow} onHide={handleClosee} className="buyNowModal">
        <Modal.Header closeButton>
          <h2>Check Out</h2>
        </Modal.Header>
        <Modal.Body>
          <BuyNow designData={data} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ShirtSvgDisplay